<template>
  <div class="certificates-container">
    <VueText sizeLevel="11" weightLevel="2" class="certificates-title">Tüm Sertifikalar</VueText>
    <div class="certificate-cards">
      <div class="certificate-card" v-for="(item, index) in certificates" :key="index">
        <div>
          <img :src="item.image" alt="Certificate Image" />
          <div class="card-details">
            <h3>{{ item.academyName }}</h3>
            <p>{{ item.description }}</p>
            <BrandButton
              v-if="!!item.isCertReady"
              @click="openWindowForCertificate(item.certUrl)"
              class="download-btn"
            >
              Hemen İndir
            </BrandButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { User } from '@/services/Api/index';

export default {
  name: 'Certificates',
  components: {
    VueText,
    BrandButton,
  },
  data() {
    return {
      certificates: [],
    };
  },
  async mounted() {
    await this.getCertificates();
  },
  methods: {
    async getCertificates() {
      const response = await User.getCertificates();
      this.certificates = response?.data?.Data?.certificates ?? [];
    },
    openWindowForCertificate(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates-container {
  display: flex;
  flex-direction: column;
}

.certificates-title {
  margin: 3% 0 2% 7%;
  align-self: flex-start;
}

.certificate-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: 7%;
}

.download-btn {
  max-width: 130px;
  margin-top: 40px;
}

.certificate-card {
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px #0000001a;
  overflow: hidden;
  margin: 2% 1%;
}

.certificate-card img {
  width: 100%;
  height: auto;
}

.card-details {
  padding: 20px;
}

.card-details h3 {
  margin-top: 0;
  font-weight: 500;
  font-size: 20px;
  color: #333;
}

.card-details p {
  color: #666;
  padding-top: 20px;
}

.download-btn {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.download-btn:hover {
  background-color: #0056b3;
}
</style>
